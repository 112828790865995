import { TTrusteeActionType } from '../../actions/entities/trustee.actions';
import { EntitiesState } from "../../types/state/entities/EntitiesState";
import { ActionType } from '../../types/ActionType';
import { bdInventoryReducer, inventoryReducerDefaultState } from "./bd-inventory.reducer";
import { inventoryReducer, allInventoryReducerDefaultState } from './inventory-reducer';
import { brokerDealerListInitialState, brokerDealerListReducer } from './broker-dealers-list.reducer';
import {
    brokerDealerContactListInitialState,
    brokerDealerContactListReducer,
} from './broker-dealer-contact-list.reducer';
import { brokerDealerUserListInitialState, brokerDealerUserListReducer } from './broker-dealer-user-list.reducer';
import {
    settlementAgentAgreementsInitialState,
    settlementAgentAgreementsReducer
} from './settlement-agent-agreements.reducer';
import {
    TAdminEditActionType,
    adminInventoryEditInitialState,
    adminInventoryEditReducer
} from './admin-inventory-edit.reducer';
import {
    priceTalksInitialState,
    priceTalksReducer
} from '../price-talks.reducer';
import { rejectedTradesReducer, rejectedTradesInitialState } from './rejected-trades.reducer';
import { TPriceTalksActionType } from '../../actions/price-talks.actions';
import { portfolioListInitialState, portfolioListReducer } from './portfolio-list.reducer';
import { accountActions } from "../../constants";
import { emailPreferencesInitialState, emailPreferencesReducer } from "../email-preferences.reducer";
import { TEmailPreferecnesActionTypes } from "../../actions/email-preferences.actions";
import { activeInventorySecurityInitialState, activeInventorySecuritiesReducer, TActiveInventorySecuritiesActionType } from './active-inventory-security.reducer';
import { settlementAgentCommissionReducer, settlementAgentCommissionInitialState } from './settlement-agent-commission.reducer';
import { TSettlementAgentCommissionActionType } from '../../actions';
import { TSettlementAccountActionType } from '../../actions/settlement-account.actions';
import { settlementAccountInitialState, settlementAccountReducer } from './settlement-account.reducer';
import { assetManagerInitialState, assetManagerReducer } from './asset-manager.reducer';
import { TAssetManagerActionType } from "../../actions/asset-manager.actions";
import { tradeAllocationInitialState, tradeAllocationReducer } from './trade-allocation.reducer';
import { TradeAllocationActionType } from "../../actions/trade-allocation.actions";
import { tradeAllocationTemplateInitialState, tradeAllocationTemplateReducer } from './trade-allocation-template.reducer';
import { TradeAllocationTemplateActionType } from "../../actions/trade-allocation-template.actions";
import { awaitingTrades, awaitingTradesInitialState } from './awaiting-trades.reducer';
import { AwaitingTradesActionTypes } from '../../actions/entities/awaitingTrades.actions';
import { TRejectedTradesActionType } from '../../actions/entities/rejected-trades.actions';
import { CollateralManagersActionTypes, collateralManagersDefaultState, collateralManagersReducer } from "./collateral-managers.reducer";
import { trusteeInitialState, trusteeReducer } from './trustee.reducer';
import { pageConfigDefaultState, pageConfigReducer } from "./page-config.reducer";
import { TPageConfigActions } from "../../actions/page-config.actions";
import { liveBiddingInitialState, liveBiddingReducer } from './live-bidding.reducer';
import { TLiveBiddingAction } from '../../actions/entities/live-bidding.actions';
import { cloSystemInfrastructureActionTypes, cloSystemInfrastructureDefaultState, cloSystemInfrastructureReducer } from './cloSystemInfrastructure.reducer';
import { partiesInitialState, partiesReducer } from './parties.reducer';
import { TPartiesActionType } from '../../actions/entities/parties.actions';
import { availableBrokerDealersInitialState, availableBrokerDealersReducer } from './availableBrokerDealers.reducer';
import { TAvailableBrokerDealersActionType } from '../../actions/entities/availableBrokerDealers.actions';

const initialState: EntitiesState = {
    inventory: inventoryReducerDefaultState,
    allInventory: allInventoryReducerDefaultState,
    brokerDealerList: brokerDealerListInitialState,
    brokerDealerContactList: brokerDealerContactListInitialState,
    brokerDealerUserList: brokerDealerUserListInitialState,
    settlementAgentAgreements: settlementAgentAgreementsInitialState,
    portfolios: portfolioListInitialState,
    adminInventoryEdit: adminInventoryEditInitialState,
    priceTalks: priceTalksInitialState,
    emailPreferences: emailPreferencesInitialState,
    activeInventorySecurities: activeInventorySecurityInitialState,
    settlementAgentCommission: settlementAgentCommissionInitialState,
    settlementAccount: settlementAccountInitialState,
    assetManager: assetManagerInitialState,
    tradeAllocation: tradeAllocationInitialState,
    tradeAllocationTemplate: tradeAllocationTemplateInitialState,
    awaitingTrades: awaitingTradesInitialState,
    rejectedTrades: rejectedTradesInitialState,
    trustee: trusteeInitialState,
    collateralManagers: collateralManagersDefaultState,
    pageConfig: pageConfigDefaultState,
    liveBidding: liveBiddingInitialState,
    cloSystemInfrastructure: cloSystemInfrastructureDefaultState,
    parties: partiesInitialState,
    availableBrokerDealers: availableBrokerDealersInitialState
};

export function entities(state = initialState, action: ActionType<any>): EntitiesState {
    if (action.type === accountActions.LOGOUT) return { ...initialState };

    const [branch, feature] = action.type.split('/');

    if (branch !== 'entities') {
        return state;
    }

    switch (feature) {
        case 'bd-inventory':
            return {
                ...state,
                inventory: bdInventoryReducer(state.inventory, action)
            };
        case 'inventory':
            return {
                ...state,
                allInventory: inventoryReducer(state.allInventory, action)
            };
        case 'admin-inventory-edit':
            return {
                ...state,
                adminInventoryEdit: adminInventoryEditReducer(state.adminInventoryEdit, action as TAdminEditActionType)
            }
        case 'broker-dealer-list':
            return {
                ...state,
                brokerDealerList: brokerDealerListReducer(state.brokerDealerList, action)
            };
        case 'broker-dealer-contact-list':
            return {
                ...state,
                brokerDealerContactList: brokerDealerContactListReducer(state.brokerDealerContactList, action)
            };
        case 'broker-dealer-user-list':
            return {
                ...state,
                brokerDealerUserList: brokerDealerUserListReducer(state.brokerDealerUserList, action)
            };
        case 'settlement-agent-agreements':
            return {
                ...state,
                settlementAgentAgreements: settlementAgentAgreementsReducer(state.settlementAgentAgreements, action)
            };
        case 'portfolio-list':
            return {
                ...state,
                portfolios: portfolioListReducer(state.portfolios, action)
            }
        case 'priceTalks':
            return {
                ...state,
                priceTalks: priceTalksReducer(state.priceTalks, action as TPriceTalksActionType)
            }
        case 'email-preferences':
            return {
                ...state,
                emailPreferences: emailPreferencesReducer(state.emailPreferences, action as TEmailPreferecnesActionTypes)
            };
        case 'active-inventories-securities':
            return {
                ...state,
                activeInventorySecurities: activeInventorySecuritiesReducer(state.activeInventorySecurities, action as TActiveInventorySecuritiesActionType)
            };
        case 'settlement-agent-commission':
            return {
                ...state,
                settlementAgentCommission: settlementAgentCommissionReducer(state.settlementAgentCommission, action as TSettlementAgentCommissionActionType)
            };
        case 'settlement-account':
            return {
                ...state,
                settlementAccount: settlementAccountReducer(state.settlementAccount, action as TSettlementAccountActionType)
            };
        case 'asset-manager':
            return {
                ...state,
                assetManager: assetManagerReducer(state.assetManager, action as TAssetManagerActionType)
            };
        case 'trade-allocation':
            return {
                ...state,
                tradeAllocation: tradeAllocationReducer(state.tradeAllocation, action as TradeAllocationActionType)
            };
        case 'trade-allocation-template':
            return {
                ...state,
                tradeAllocationTemplate: tradeAllocationTemplateReducer(state.tradeAllocationTemplate, action as TradeAllocationTemplateActionType)
            };
        case 'awaitingTrades':
            return {
                ...state,
                awaitingTrades: awaitingTrades(state.awaitingTrades, action as AwaitingTradesActionTypes)
            }
        case 'rejected-trades':
            return {
                ...state,
                rejectedTrades: rejectedTradesReducer(state.rejectedTrades, action as TRejectedTradesActionType)
            }
        case 'collateral-managers':
            return {
                ...state,
                collateralManagers: collateralManagersReducer(state.collateralManagers, action as CollateralManagersActionTypes)
            }
        case 'trustee':
            return {
                ...state,
                trustee: trusteeReducer(state.trustee, action as TTrusteeActionType)
            }
        case 'page-config':
            return {
                ...state,
                pageConfig: pageConfigReducer(state.pageConfig, action as TPageConfigActions)
            }
        case 'live-bidding':
            return {
                ...state,
                liveBidding: liveBiddingReducer(state.liveBidding, action as TLiveBiddingAction)
            }
        case 'cloSystemInfrastructure':
            return {
                ...state,
                cloSystemInfrastructure: cloSystemInfrastructureReducer(state.cloSystemInfrastructure, action as cloSystemInfrastructureActionTypes)
            }
        case 'parties':
            return {
                ...state,
                parties: partiesReducer(state.parties, action as TPartiesActionType),
            };
        case 'availableBrokerDealers':
            return {
                ...state,
                availableBrokerDealers: availableBrokerDealersReducer(state.availableBrokerDealers, action as TAvailableBrokerDealersActionType),
            }
        default:
            return state;
    }
}
